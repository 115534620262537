import * as React from 'react';
import Seo from '../components/seo';
import Header from '../components/Header101/Header';
import HeroSection from '../components/HeroSection/HeroSection';
import Footer from '../components/Footer6/Footer';
import FeatureSectionCenterNoimage from '../components/FeatureSectionCenterNoimage/FeatureSectionCenterNoimage';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import H1CenterSection from '../components/H1CenterSection/H1CenterSection';

const WazaiDayservicePage = ({ data, location }) => {
  return (
    <div>
      <Header
        title="団欒処 和菜屋"
        logoImage="/wazai/logo.png"
        logoImage2="/wazai/logo2.png"
        navItems={[
          {
            id: 1,
            name: '事業案内',
            link: '/about',
            dropdown: false,
          },
          {
            id: 2,
            name: 'こだわり',
            link: '/founder',
            dropdown: false,
          },
          {
            id: 3,
            name: 'アクセス',
            link: '/access',
            dropdown: false,
          },
          {
            id: 4,
            name: '求人情報',
            link: '/recruit',
            dropdown: false,
          },
        ]}
      />
      <HeroSection imagePath="/wazai/g3.jpg" heading="デイサービス 和才屋" />

      <FeatureSectionCenterNoimage
        sectionId="section1"
        description={`
        日田杉に囲まれた心地よい空間のデイサービス「和才屋」。
        
        団らん処「和菜屋」の食事を、小石原焼秀山窯の食器でお楽しみいただけます。
        
        利用者様の能力や個性に合わせた作業活動や、高齢者だからこそできる社会的な交流にも取り組んでいます。
        
        ご見学・ご体験はいつでも大歓迎です。お気軽にお問い合わせください。`}
      />
      <H1CenterSection title="デイサービス 和才屋の特徴" />
      <FeatureSectionLeft
        imageSrc="/wazai/dayservice/section1.webp"
        imageAlt="Image 1"
        title="心地よい空間でくつろぎを。"
        description={`天然の杉の香りに包まれた、リフレッシュ効果のある癒しの空間。
        家具や床材、天井にも日田杉を使用しています。
        旅館のような上質な空間で、ご自宅のようなくつろぎの時間を。
        居心地の良さと、アットホームで温かい関わりを大切にしています｡
        `}
      />
      <FeatureSectionRight
        imageSrc="/wazai/dayservice/section2.webp"
        imageAlt="Image 2"
        title="こだわりの美味しい食事。"
        description={`惣菜店「和菜屋」のお料理を、施設内で作るご飯・お味噌汁と一緒に。
        野菜は小倉南区合馬などの地元農家から、 魚や肉は朝市で仕入れています。
        北九州で長年親し
        まれてきた小石原焼きの食器で、より豊かな食卓を演出しています。
        作り置きや冷凍ではない、出来立ての家庭的なお食事をお楽しみください。
        `}
        sectionId="section2"
      />
      <FeatureSectionLeft
        imageSrc="/wazai/dayservice/section3.webp"
        imageAlt="Image 1"
        title="笑顔が広がる多彩な作業活動"
        description={`手芸、エコクラフト、書道、絵画、生け花、
        工作、囲碁、俳句、読書などなど。
        趣味や特技を広げ、知的好奇心を満たすようなを活動を心掛けています。
        自宅では様々な事情で出来ない事にも、
        出来る限り工夫しながら一緒に取り組みます。
        `}
        sectionId="section3"
      />

      <FeatureSectionRight
        imageSrc="/wazai/dayservice/section4.webp"
        imageAlt="Image 2"
        title="健やかで居るための体操"
        description={`専門の看護師によるリハビリ体操を実施。
        一人でも出来るような簡単な動きを中心に、健康に関する情報もお伝えします。
        機能訓練指導員や作業療法士が、個別に身体機能を確認し、必要に応じて起立・歩行練習や筋力増強運動を実施しています。
        昼食前には、安全に召し上がっていただけるよう口腔体操を取り入れています。
        `}
        sectionId="section4"
      />
      <FeatureSectionLeft
        imageSrc="/wazai/dayservice/section5.webp"
        imageAlt="Image 1"
        title="リムジン車での送迎"
        description={`
        送迎中も上質なおもてなしでご満足いただきたい｡
        そんな想いからリムジン車での送迎サービスもございます。（※要相談）
        ​ちょっぴりお洒落してお出掛けしたくなるような気分を楽しんでいただけますように。
        `}
        sectionId="section5"
      />
      <Footer
        items={[
          {
            categoriesname: '事業内容',
            categories: [
              { name: '団らん処和菜屋', link: '/' },
              { name: 'デイサービス和才屋', link: '/dayservice' },
              {
                name: 'ネットショップ(準備中)',
                link: 'https://wazaiya.base.shop/',
              },
              { name: 'アクセス', link: '/access' },
            ],
          },
          {
            categoriesname: '会社概要',
            categories: [
              { name: '会社概要', link: '/about' },
              { name: '創業者の想い', link: '/founder' },
              { name: '活動実績', link: '/history' },
              { name: 'プライバシーポリシー', link: '/privacy' },
            ],
          },
          {
            categoriesname: '採用',
            categories: [{ name: '求人情報', link: '/recruit' }],
          },
        ]}
        logo="/wazai/logo.png"
        logoAltText="和菜屋"
        copyrightText="団らん処 和菜屋"
        logoLinkText="/"
        facebookUrl="https://www.facebook.com/wazaiya.kokura/"
        xUrl="https://twitter.com/wazaiya_sozai"
        instagramUrl="https://www.instagram.com/wazaiya.sozai/"
      />
    </div>
  );
};

export default WazaiDayservicePage;

export const Head = () => (
  <Seo
    title={'デイサービス 和才屋'}
    description={`団らん処「和菜屋」の食事を、小石原焼秀山窯の食器でお楽しみいただけます
利用者様の能力や個性に合わせた作業活動や、高齢者だからこそできる社会的な交流にも取り組んでいます。
ご見学・ご体験はいつでも大歓迎です。お気軽にお問い合わせください。`}
  />
);
